<template>
    <content-loader :height="300" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="55" y="50" rx="5" ry="5" width="260" height="15" />
        <rect x="55" y="100" rx="5" ry="5" width="260" height="15" />
        <rect x="55" y="150" rx="5" ry="5" width="260" height="15" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
